<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgMsgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Search Customers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
            <v-card>
                <v-form ref="formSearch">
            <v-row class="mx-1">
                <v-col cols="12" md="4">
                    <v-select
                        v-model="form.search_key"
                        :items="searchKeyList"
                        item-text="description"
                        item-value="code"
                        label="Search Key"
                        standard
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        v-model="form.search_word"
                        label="Search Word"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required, rules.counter]"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="form.data_limit"
                        :items="dataLimitList"
                        item-text="description"
                        item-value="code"
                        label="Data Limit"
                        standard
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="1">
                    <v-btn icon condensed color="panel1" @click="refreshDataTable">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                
            </v-row>
                </v-form>
            </v-card>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>
        <v-data-table
        item-key="customer_id"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"
        @click:row="openDetail"

        ></v-data-table>
        </v-card>
        </div>

    </v-card>
</template>
<script>
import ApCustomerService from '@/services/ApCustomerService'
import DlgMessage from "@/components/DlgMessage";

export default {
    components:{DlgMessage},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Customers',disabled: false,href:'/customers',},
              {text:'Search',disabled: false,href:'#',},
              ],
            tblHeaders:[
                { text:"Login Id",value: "login_id"  },
                { text:"Full Name", value:"full_name" },
                { text:"Cell Phone #", value:"cell_phone" },
                { text:"Point Balance", value:"point_balance" },
                { text:"Status", value:"mbr_status" },
                { text:"Reg. Date", value:"mbr_registration_date" },

            ],
            tblBody:[],
            tblSearch:'',
            searchKey:'login_id',
            searchKeyList:[
                {code:'login_id',description:'Login Id'},
                {code:'full_name',description:'Full Name'},
                {code:'cell_phone',description:'Mobile Phone'},
            ],

            dataLimit:500,
            dataLimitList:[
                {code:500,description:'500 rows'},
                {code:1000,description:'1000 rows'},
                {code:2000,description:'2000 rows'},
                {code:5000,description:'5000 rows'},
            ],
            loadingFlag:false,
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length >= 3 || 'Min 3 characters',
            },
            form:{
                login_id:"",
                search_key:"login_id",
                search_word:"",
                data_limit:500
            }
        }
    },
    methods:{
            async refreshDataTable(){

                if(this.$refs.formSearch.validate()){

                    this.loadingFlag = true
                    let title = 'System';
                    try{
                        ApCustomerService.search_customer(this.form).then((res) => {
                            this.tblBody = res.data.data;
                            this.loadingFlag = false;
                        }).catch((e)=>{
                                //console.log(e.response);
                            if(e.response.data.error_message)
                                this.$refs.dlgMsg.showDlgBox(true,'error',title,e.response.data.error_message);
                            this.loadingFlag = false;
                        })
                            ;
                    } catch(e) {
                        if(e)
                            this.$refs.dlgMsg.showDlgBox(true,'error',title,'Unknown Error');
                        this.loadingFlag = false;
                    } 

                }
            },
            dlgMsgClose(){
            },
            openDetail(value){
                console.log(value)
                this.$router.push({ name: 'customers-details', params: { customer_id: value.customer_id } })
                //this.$router.push("/merchants/detail/" + value.merchant_id);
            },

    }
}
</script>